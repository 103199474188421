import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  NativeSelect,
  TextField,
  Typography
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  DateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
  roRO
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { ActionType } from "../../Model/ActionTypes.tsx";
import { api } from "../../Api/api.tsx";
import MultiLineTable from "../../Components/MultiLineTable.tsx";
import userInfoProvider from "../../Providers/UserInfoProvider.tsx";
import { notificationType, setNotification } from "../../Utils/Notificator.tsx";

export const DepartamenteModal = (props) => {
  const [entity, setEntity] = useState<any>();

  useEffect(() => {}, []);

  const updateEntity = (event) => {
    var copy = Object.assign({}, entity);
    copy[event.target.name] = event.target.value;
    setEntity(copy);
  };

  const save = async (e) => {
    e.preventDefault();
    if (props.action == ActionType.ADD) {
    } else {
    }

    props.reloadData(true);
    props.closeModal();
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Dialog open={true} onClose={props.closeModal} fullWidth maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography fontSize={30}>Lucrare</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
            "& svg": {
              fontSize: 32
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form id="formCereri" onSubmit={save}>
            <Grid container spacing={3} sx={{ marginTop: "10px" }}>
              <Grid item xs={12} sx={{ paddingBottom: "2vh" }}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Beneficiar"
                  name="beneficiar"
                  onChange={updateEntity}
                  value={entity?.beneficiar}
                  fullWidth
                  required
                  disabled={props.action == ActionType.VIEW}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Descriere"
                  name="descriere"
                  onChange={updateEntity}
                  value={entity?.descriere}
                  rows={6}
                  required
                  multiline
                  fullWidth
                  disabled={props.action == ActionType.VIEW}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {props.action != ActionType.VIEW ? (
          <>
            <DialogActions>
              <Button
                type="submit"
                form="formCereri"
                color="success"
                variant="contained"
              >
                Salvează
              </Button>
            </DialogActions>
          </>
        ) : (
          ""
        )}
      </Dialog>
    </div>
  );
};

export default DepartamenteModal;
