import { Fade, Tooltip } from "@mui/material";
import React from "react";

export const TableTooltipCell = (props) => {
  return (
    <Tooltip
      title={props.value}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
    >
      <>
        <span title={props.value + ""}>{props.value + ""}</span>
      </>
    </Tooltip>
  );
};

export default TableTooltipCell;
