import { Cereri } from "../Pages/Cereri/Cereri.tsx";
import Lucrari from "../Pages/Lucrari/Lucrari.tsx";
import { Trasee } from "../Pages/Trasee/Trasee.tsx";
import {Rol} from "../Model/Roluri.tsx";
import Montaje from "../Pages/Montaje/Montaje.tsx";
import Utilizatori from "../Pages/Utilizatori/Utilizatori.tsx";
import Departamente from "../Pages/Departamente/Departamente.tsx";
const AppRoutes = [
    {
        path: "/",
        element: <Cereri />,
        menuName: "Cereri",
        rols: [Rol.AGENT]
    },
    {
        path: "/Lucrari",
        menuName: "Lucrări",
        element: <Lucrari/>,
        rols: [Rol.AGENT,Rol.LUCRATOR]
    },
    {
        path: "/Montaje",
        menuName: "Montaje",
        element: <Montaje />,
        rols: [Rol.AGENT,Rol.LUCRATOR]
    },
    {
        path: "/Trasee",
        element: <Trasee />,
        menuName: "Trasee",
        rols: [Rol.AGENT,Rol.AGENTTRANSPORT,Rol.LUCRATOR]
    },
    {
        path: "/Utilizatori",
        menuName: "Utilizatori",
        element: <Utilizatori />,
        rols: [Rol.ADMINISTRATOR,Rol.AGENT]
    },
    {
        path: "/Departamente",
        menuName: "Departamente",
        element: <Departamente />,
        rols: [Rol.ADMINISTRATOR]
    }
];

export default AppRoutes;
