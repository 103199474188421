import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef
} from "material-react-table";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { roRO } from "@mui/x-date-pickers/locales";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, Button, IconButton } from "@mui/material";
import { Add, Edit, Done, History, Preview } from "@mui/icons-material";
import { ActionType } from "../../Model/ActionTypes.tsx";
import TableTooltipCell from "../../Components/TableTooltipCell.tsx";
import TraseeModal from "./_TraseeModal.tsx";
import { api } from "../../Api/api.tsx";
import userInfoProvider from "../../Providers/UserInfoProvider.tsx";
import { Rol } from "../../Model/Roluri.tsx";
import { showModal } from "../../Utils/ShowModalMessage.tsx";
import { notificationType, setNotification } from "../../Utils/Notificator.tsx";
import { toJS } from "mobx";

export const Trasee = () => {
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, openchange] = useState(false);
  const [entitySelected, setEntitySelected] = useState({});
  const [action, setAction] = useState<any>();
  const [isHistory, setIsHistory] = useState(false);

  useEffect(() => {
    userInfoProvider.changePage("Trasee");
    loadData();
  }, [isHistory]);

  const loadData = async () => {
    setIsLoading(true);
    var response;

    if (isHistory === false) {
      response = await api.trasee.getActive();
      if (response !== undefined) {
        setPageData(response);
      }
    } else {
      response = await api.trasee.page(null, null);
      if (response !== undefined) {
        setPageData(response);
      }
    }
    setIsLoading(false);
  };

  const done = (row) => {
    showModal(
      {
        title: "Ești sigur că trasul este finalizat?"
      },
      async () => {
        if ((await api.trasee.done(row.original.id)) != undefined) {
          loadData();
          setNotification(
            notificationType.OK,
            "Status traseu modificat",
            "Modificare status traseu"
          );
        }
      }
    );
  };

  const openModal = (data = {}, action = ActionType.ADD) => {
    setAction(action);
    setEntitySelected(data);
    openchange(true);
  };

  const closeModal = () => {
    openchange(false);
  };

  const shouldShowActionButtons = (entity) => {
    return (
      isHistory == false &&
      (entity.idAgent == toJS(userInfoProvider.state).userId ||
        userInfoProvider.hasAccess(Rol.ADMINISTRATOR))
    );
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "beneficiar",
        header: "Beneficiar",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru beneficiar" }
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.termen),
        accessorKey: "Termen",
        header: "Termen",
        filterVariant: "date",
        muiFilterDatePickerProps: {
          format: "dd/MM/yyyy"
        },
        filterFn: (row: any, id, filterValue) => {
          row.getValue(id).setHours(0, 0, 0, 0);
          filterValue.setHours(0, 0, 0, 0);
          return row.getValue(id).getTime() == filterValue.getTime();
        },
        size: 50,
        Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString("ro-RO"),
        muiFilterTextFieldProps: { placeholder: "Filtru termen" }
      },
      {
        accessorKey: "agent",
        header: "Agent",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru agent" }
      },
      {
        accessorKey: "lucrator",
        header: "Lucrător",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru lucrător" }
      },
      {
        Cell: ({ cell }) => <TableTooltipCell value={cell.getValue()} />,
        accessorKey: "descriere",
        header: "Descriere",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru descriere" },
        size: 400
      }
    ],
    []
  );

  const customToolbar = () => {
    return (
      <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
        {userInfoProvider.hasAccess([Rol.AGENT, Rol.AGENTTRANSPORT]) && (
          <Button
            onClick={() => {
              openModal();
            }}
            variant="contained"
          >
            <Add style={{ marginRight: 10 }} />
            Adaugă traseu
          </Button>
        )}
        <Button
          onClick={() => {
            setIsHistory(!isHistory);
          }}
          variant="contained"
          sx={{ backgroundColor: "brown" }}
        >
          <History style={{ marginRight: 10 }} />
          {isHistory === false ? "Istoric" : "Active"}
        </Button>
      </Box>
    );
  };

  const customActions = ({ row }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "center"
        }}
      >
        <IconButton
          color="primary"
          title="Vizualizează"
          onClick={() => {
            openModal(row.original, ActionType.VIEW);
          }}
        >
          <Preview />
        </IconButton>
        {shouldShowActionButtons(row.original) && (
          <IconButton
            color="secondary"
            title="Editează"
            onClick={() => {
              openModal(row.original, ActionType.UPDATE);
            }}
          >
            <Edit />
          </IconButton>
        )}
        {(shouldShowActionButtons(row.original) ||
          row.original.idLucrator == toJS(userInfoProvider.state).userId) && (
          <IconButton
            color="success"
            title="Finalizează traseul"
            onClick={() => done(row)}
          >
            <Done />
          </IconButton>
        )}
      </Box>
    );
  };

  const table = useMaterialReactTable({
    columns,
    data: pageData,
    initialState: {
      showColumnFilters: true,
      pagination: { pageSize: 20, pageIndex: 0 }
    },
    enableSorting: false,
    enableGlobalFilter: false,
    enableColumnActions: false,

    renderTopToolbarCustomActions: customToolbar,

    enableRowActions: true,
    renderRowActions: customActions,

    state: {
      isLoading
    },

    muiTableBodyCellProps: {
      sx: {
        borderRight: "2px solid #e0e0e0",
        whiteSpace: "break-spaces",
        maxWidth: 400
      }
    },
    localization: {
      actions: "Acțiuni",
      rowsPerPage: "Rânduri pe pagină"
    }
  });

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={
          roRO.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <MaterialReactTable table={table} />
        {open && (
          <TraseeModal
            closeModal={closeModal}
            entity={entitySelected}
            action={action}
            reloadData={loadData}
          />
        )}
      </LocalizationProvider>
    </>
  );
};

export default Trasee;
