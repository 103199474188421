import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import userInfoProvider from "../../Providers/UserInfoProvider.tsx";
import { useEffect, useState } from "react";
import React from "react";
import { api } from "../../Api/api.tsx";
import { observer } from "mobx-react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Rol } from "../../Model/Roluri.tsx";
import { notificationType, setNotification } from "../../Utils/Notificator.tsx";
import LoadingButton from "@mui/lab/LoadingButton";

const defaultTheme = createTheme();

export const Login = observer(() => {
  const [email, setEmail] = useState<any>();
  const [password, setPassword] = useState<any>();
  const [secondPassword, setSecondPassword] = useState<any>();
  const [rememberMe, setRememberMe] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get("token") != undefined) {
      userInfoProvider.setUser(Cookies.get("token"), false);
      if (userInfoProvider.hasAccess([Rol.LUCRATOR])) {
        navigate("/Lucrari");
      } else if (userInfoProvider.hasAccess([Rol.AGENTTRANSPORT])) {
        navigate("/Trasee");
      }
    }
  }, []);

  const changePageDependingOnRol = () => {
    if (userInfoProvider.hasAccess([Rol.AGENT])) {
      navigate("/");
    } else if (userInfoProvider.hasAccess([Rol.LUCRATOR])) {
      navigate("/Lucrari");
    } else if (userInfoProvider.hasAccess([Rol.AGENTTRANSPORT])) {
      navigate("/Trasee");
    }
  };

  const normalLogin = async () => {
    let data = {
      email: email,
      parola: password
    };

    var response = await api.utilizatori.login(data);
    setLoading(false);

    if (response == "RESET") {
      setPasswordReset(true);
      setNotification(
        notificationType.WARNING,
        "Vă rugăm să vă resetați parola",
        "Resetare parolă"
      );
    } else if (response != "ERR") {
      if (rememberMe == true) {
        userInfoProvider.setUser(response, true, 7);
      } else {
        userInfoProvider.setUser(response);
      }
      changePageDependingOnRol();
    } else {
      setNotification(
        notificationType.ERROR,
        "Vă rugăm să reîncercați.",
        "Contul nu este corect."
      );
    }
  };

  const resetPassLogin = async () => {
    if (password != secondPassword) {
      setNotification(notificationType.ERROR, "Parolele nu coincid", "Eroare");
    } else {
      let data = {
        email: email,
        parola: password
      };
      var response = await api.utilizatori.loginWithPassReset(data);
      if (response != "ERR") {
        if (rememberMe == true) {
          userInfoProvider.setUser(response, true, 7);
        } else {
          userInfoProvider.setUser(response);
        }
        changePageDependingOnRol();
      } else {
        setNotification(
          notificationType.ERROR,
          "Vă rugăm să reîncercați.",
          "Eroare la încărcarea datelor."
        );
      }
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (passwordReset == false) {
      normalLogin();
    } else {
      resetPassLogin();
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            paddingTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Alma Solution
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Adresă email"
              name="email"
              autoComplete="email"
              type="email"
              autoFocus
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Parolă"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {passwordReset && (
              <TextField
                margin="normal"
                required
                fullWidth
                name="secondPassword"
                label="Repetare parolă"
                type="password"
                id="password"
                onChange={(e) => {
                  setSecondPassword(e.target.value);
                }}
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
              }
              label="Ține-mă minte"
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loadingPosition="start"
              loading={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              Log in
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
});

export default Login;
