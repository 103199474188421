import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import Kanban from "./Kanban.tsx";
import { notificationType, setNotification } from "../../Utils/Notificator.tsx";
import { api } from "../../Api/api.tsx";
import userInfoProvider from "../../Providers/UserInfoProvider.tsx";

export const SarciniModal = (props) => {
  const [descriereSarcina, setdescriereSarcina] = useState("");
  const [isUrgent, setIsUrgent] = useState(true);
  const [sarcini, setSarcini] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    var response = await api.sarcini.getAll();
    if (response != undefined) {
      setSarcini(Object.assign({}, response));
      setLoading(false);
    }
  };

  const addSarcina = async (e) => {
    e.preventDefault();
    var listaSarcini = isUrgent
      ? sarcini["sarciniUrgente"]
      : sarcini["sarciniGenerale"];

    var maxOrd =
      listaSarcini.length > 0
        ? Math.max(...listaSarcini.map((o) => o.ordine))
        : -1;

    var resp = await api.sarcini.save({
      descriere: descriereSarcina,
      tip: Number(isUrgent),
      idUtilizator: userInfoProvider.getUserId(),
      ordine: maxOrd + 1
    });

    if (resp != -1) {
      setNotification(
        notificationType.OK,
        "Salvare sarcină",
        "Sarcină salvată cu succes"
      );
      loadData();
      setIsUrgent(true);
      setdescriereSarcina("");
      userInfoProvider.sarciniModification(1);
    }
  };

  return (
    !loading && (
      <div style={{ textAlign: "center" }}>
        <Dialog
          open={true}
          onClose={props.closeModal}
          fullWidth
          maxWidth="md"
          sx={{ zIndex: "1000" }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <Typography fontSize={30}>Sarcini</Typography>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={props.closeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "red",
              "& svg": {
                fontSize: 32
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <form>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent={"space-between"}
              >
                <TextField
                  variant="outlined"
                  label="Sarcină"
                  value={descriereSarcina}
                  onChange={(e) => setdescriereSarcina(e.target.value)}
                  fullWidth
                ></TextField>
                <FormControlLabel
                  label="Este urgentă?"
                  sx={{ flex: "none" }}
                  control={
                    <Checkbox
                      checked={isUrgent}
                      onChange={(e) => setIsUrgent(e.target.checked)}
                      sx={{
                        color: "#C30913",
                        "&.Mui-checked": {
                          color: "#C30913"
                        },
                        "& .MuiSvgIcon-root": { fontSize: 35 },
                        padding: 0
                      }}
                    />
                  }
                  labelPlacement="top"
                />
                <Button
                  type="submit"
                  color="success"
                  sx={{
                    "& svg": {
                      fontSize: 50
                    },
                    height: "50px",
                    width: "50px",
                    alignSelf: "center"
                  }}
                  variant="outlined"
                  onClick={addSarcina}
                >
                  <Add />
                </Button>
              </Stack>
            </form>
            <hr />
            <Kanban
              sarciniGenerale={sarcini["sarciniGenerale"]}
              sarciniUrgente={sarcini["sarciniUrgente"]}
            />
          </DialogContent>
        </Dialog>
      </div>
    )
  );
};

export default SarciniModal;
