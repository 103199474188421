import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  styled
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { api } from "../../Api/api.tsx";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: "black"
}));

export const IncarcareModal = (props) => {
  const [cereri, setCereri] = useState<any>([]);
  const [lucrari, setLucrari] = useState<any>([]);

  useEffect(() => {
    console.log(props.id);
    loadData();
  }, []);

  const loadData = async () => {
    var response = await api.utilizatori.userLoad(props.id);
    if (response != undefined) {
      setCereri(response.cereri);
      setLucrari(response.lucrari);
      console.log(response);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Dialog open={true} onClose={props.closeModal} fullWidth maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography fontSize={30}>
            Încărcare {props.nume + " " + props.prenume}{" "}
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
            "& svg": {
              fontSize: 32
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack direction="row" width={"inherit"} spacing={2}>
            <Stack spacing={2} width={"50%"}>
              <Typography>Cereri({cereri.length})</Typography>
              {cereri.length > 0 ? (
                cereri.map((item) => {
                  return <Item>{item}</Item>;
                })
              ) : (
                <Item>Nu are cereri active</Item>
              )}
            </Stack>
            <Stack spacing={2} width={"50%"}>
              <Typography>Lucrări({lucrari.length})</Typography>
              {lucrari.length > 0 ? (
                lucrari.map((item) => {
                  return <Item>{item}</Item>;
                })
              ) : (
                <Item>Nu are lucrări active</Item>
              )}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default IncarcareModal;
