import { Store } from "react-notifications-component"

export const notificationType = {
    ERROR: "danger",
    WARNING: "warning",
    OK: "success"
}

export const setNotification = (Type,Message,Title) =>{
    Store.addNotification({
        title: Title,
        message: Message,
        type: Type,
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated","animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen:true
        }
    });
}