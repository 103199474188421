export const StatusCerere = [
  {
    value: "0",
    label: "Primită",
  },
  {
    value: "1",
    label: "Acceptată",
  },
  {
    value: "2",
    label: "Refuzată",
  },
  {
    value: "3",
    label: "Prelucrată",
  }
];

export const StatusLucrare = [
  {
    value: "0",
    label: "In Lucru",
  },
  {
    value: "1",
    label: "Finalizata",
  }
];
