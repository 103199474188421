import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Done } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { api } from "../../Api/api.tsx";
import { showModal } from "../../Utils/ShowModalMessage.tsx";
import { notificationType, setNotification } from "../../Utils/Notificator.tsx";

const TaskCard = ({ item, index, doneFunc }) => {
  const finish = async (id: Number) => {
    showModal({ title: "Ai rezolvat aceasta sarcină?" }, async () => {
      if ((await api.sarcini.delete(id)) != undefined) {
        setNotification(
          notificationType.OK,
          "Finalizare sarcină",
          "Sarcina a fost finalizată"
        );
        doneFunc();
      }
    });
  };

  return (
    <Draggable key={item.id} draggableId={item.id + ""} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="taskInformation">
            <p style={{ paddingRight: "5px", minWidth: "88%" }}>
              {item.descriere}
            </p>
            <IconButton
              color="success"
              title="Confirmă Cerere"
              onClick={() => finish(item.id)}
              sx={{ padding: 0 }}
            >
              <Done />
            </IconButton>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
