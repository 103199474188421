import React from "react";
import { Container } from "reactstrap";
import NavBar from "./NavBar";
import "../AlmaSolutionStyles.css";
import userInfoProvider from "../Providers/UserInfoProvider.tsx";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react";
import { toJS } from "mobx";

export const Layout = observer((props) => {
  return (
    <div>
      <NavBar provider={userInfoProvider} />
      <Container id="mainBodyContainer">
        <Breadcrumbs aria-label="breadcrumb" sx={{ paddingBottom: "2vh" }}>
          <Typography color="text.secondary">Alma Solution</Typography>
          <Typography color="text.primary">
            {toJS(userInfoProvider.utils.currentPage)}
          </Typography>
        </Breadcrumbs>
        {props.children}
      </Container>
    </div>
  );
});

export default Layout;
