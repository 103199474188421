import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  NativeSelect,
  TextField,
  Typography
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  LocalizationProvider,
  MobileDatePicker,
  roRO
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { ActionType } from "../../Model/ActionTypes.tsx";
import { api } from "../../Api/api.tsx";
import userInfoProvider from "../../Providers/UserInfoProvider.tsx";
import { Rol } from "../../Model/Roluri.tsx";
import { notificationType, setNotification } from "../../Utils/Notificator.tsx";

interface Traseu {
  id: number;
  beneficiar: string;
  idAgent: string;
  idLucrator: string;
  descriere: string;
  termen: Dayjs;
}

export const TraseeModal = (props) => {
  const [entity, setEntity] = useState<Traseu>(Object);
  const [agents, setAgens] = useState([]);
  const [lucratori, setLucratori] = useState([]);

  useEffect(() => {
    var oldEntity: Traseu = Object.assign({}, props.entity);
    if (props.action == ActionType.ADD) {
      oldEntity.termen = dayjs(new Date());
      oldEntity.idAgent = userInfoProvider.getUserId() + "";
      oldEntity.idLucrator = "-1";
    }
    loadData();
    setEntity(oldEntity);
  }, []);

  const loadData = async () => {
    await api.utilizatori.getAllAgents().then(setAgens);
    await api.utilizatori.getAllWorkers().then(setLucratori);
  };

  const updateEntity = (event) => {
    var copy = { ...entity };
    copy[event.target.name] = event.target.value;
    setEntity(copy);
  };

  const updateTermenField = (value) => {
    var copy = Object.assign({}, entity);
    copy["termen"] = value;
    setEntity(copy);
  };

  const save = async (e) => {
    e.preventDefault();
    var objToSave: Traseu = Object.assign({}, entity);
    if (objToSave.idLucrator == "-1") {
      objToSave.idLucrator = lucratori[0]["key"];
    }

    var response;
    if (props.action == ActionType.ADD) {
      response = await api.trasee.save(objToSave);
    } else {
      response = await api.trasee.update(objToSave);
    }

    if (response.data != -1) {
      setNotification(
        notificationType.OK,
        "Traseu adăugat",
        "Traseul a fost prelucrat cu succes"
      );
      props.reloadData();
      props.closeModal();
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Dialog open={true} onClose={props.closeModal} fullWidth maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography fontSize={30}>Traseu</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
            "& svg": {
              fontSize: 32
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form id="formTrasee" onSubmit={save}>
            <Grid
              container
              spacing={3}
              sx={{ height: "50vh", marginTop: "10px" }}
            >
              <Grid item xs={5}>
                <Grid container spacing={10}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="Beneficiar"
                      name="beneficiar"
                      onChange={updateEntity}
                      value={entity.beneficiar}
                      fullWidth
                      required
                      disabled={props.action == ActionType.VIEW}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        format="DD-MM-YYYY"
                        onChange={updateTermenField}
                        value={dayjs(entity.termen)}
                        label="Termen"
                        slotProps={{
                          textField: {
                            required: true
                          }
                        }}
                        sx={{ width: "100%" }}
                        disabled={props.action == ActionType.VIEW}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <NativeSelect
                      name="idLucrator"
                      placeholder="Lucrator"
                      value={entity["idLucrator"]}
                      fullWidth={true}
                      onChange={updateEntity}
                      disabled={props.action == ActionType.VIEW}
                    >
                      {lucratori.map((c) => (
                        <option key={c["key"]} value={c["key"]}>
                          {c["value"]}
                        </option>
                      ))}
                    </NativeSelect>
                  </Grid>
                  {userInfoProvider.hasAccess(Rol.ADMINISTRATOR) && (
                    <Grid item xs={12}>
                      <NativeSelect
                        name="idAgent"
                        placeholder="Agent"
                        value={entity["idAgent"]}
                        fullWidth={true}
                        onChange={updateEntity}
                        disabled={props.action == ActionType.VIEW}
                      >
                        {agents.map((c) => (
                          <option key={c["key"]} value={c["key"]}>
                            {c["value"]}
                          </option>
                        ))}
                      </NativeSelect>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  type="text"
                  variant="outlined"
                  label="Descriere"
                  name="descriere"
                  onChange={updateEntity}
                  value={entity["descriere"]}
                  rows={13}
                  required
                  multiline
                  fullWidth
                  disabled={props.action == ActionType.VIEW}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {props.action != ActionType.VIEW ? (
          <>
            <DialogActions>
              <Button
                type="submit"
                form="formTrasee"
                color="success"
                variant="contained"
              >
                Salvează
              </Button>
            </DialogActions>
          </>
        ) : (
          ""
        )}
      </Dialog>
    </div>
  );
};

export default TraseeModal;
