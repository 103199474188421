import axios from "axios";
import { MakeRequest } from "../Utils/MakeHttpRequest.tsx";

export const baseApi = (base) => {
  return {
    getById: (id) => MakeRequest("get", base + "getById" + "/" + id, {}),

    page: (pagination, filter) => {
      return MakeRequest("post", base + "page", {});
    },

    save: (entity) => {
      return MakeRequest("post", base + "save", entity);
    },

    update: (entity) => {
      return MakeRequest("post", base + "update", entity);
    },

    delete: (id) => MakeRequest("put", base + "delete/" + id, {}),
  };
};
