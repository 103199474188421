import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./MainComponents/App";
import userInfoProvider from "./Providers/UserInfoProvider.tsx";
import { ReactNotifications } from "react-notifications-component";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <ReactNotifications />
    <App provider={userInfoProvider} />
  </BrowserRouter>
);
