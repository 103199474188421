import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef
} from "material-react-table";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { roRO } from "@mui/x-date-pickers/locales";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, Button, IconButton } from "@mui/material";
import { Add, Edit, Done, History, Preview, Delete } from "@mui/icons-material";
import { ActionType } from "../../Model/ActionTypes.tsx";
import { Rol, RolArray } from "../../Model/Roluri.tsx";
import UtilizatoriModal from "./_UtilizatoriModal.tsx";
import { api } from "../../Api/api.tsx";
import userInfoProvider from "../../Providers/UserInfoProvider.tsx";
import { showModal } from "../../Utils/ShowModalMessage.tsx";
import { notificationType, setNotification } from "../../Utils/Notificator.tsx";
import IncarcareModal from "./_IncarcareModal.tsx";
import LockResetIcon from "@mui/icons-material/LockReset";

export const Utilizatori = () => {
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, openchange] = useState(false);
  const [isVisibleIncarcareModal, openIncarcareModal] = useState(false);
  const [entitySelected, setEntitySelected] = useState({});
  const [action, setAction] = useState<any>();

  useEffect(() => {
    userInfoProvider.changePage("Utilizatori");
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    var response = await api.utilizatori.page(null, null);
    if (response != undefined) {
      setPageData(response);
    }
    setIsLoading(false);
  };

  const openModal = (data = {}, action = ActionType.ADD) => {
    setAction(action);
    setEntitySelected(data);
    openchange(true);
  };

  const closeModal = () => {
    openchange(false);
  };

  const deleteUser = (row) => {
    showModal(
      { title: "Ești sigur că dorești să ștergi acest utilizator?" },
      async () => {
        if ((await api.utilizatori.delete(row.original.id)) != undefined) {
          pageData.splice(row.index, 1);
          setPageData([...pageData]);
          setNotification(
            notificationType.OK,
            "Utilizatorul a fost șters cu succes",
            "Ștergere utilizator"
          );
        }
      }
    );
  };

  const resetUser = (row) => {
    showModal(
      { title: "Ești sigur că dorești să resetezi parola acestui utilizator?" },
      async () => {
        if (
          (await api.utilizatori.resetPassword(row.original.id)) != undefined
        ) {
          setNotification(
            notificationType.OK,
            "Parola a fost resetată cu succes",
            "Resetare parolă"
          );
        }
      }
    );
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "nume",
        header: "Nume",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru nume" }
      },
      {
        accessorKey: "prenume",
        header: "Prenume",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru prenume" }
      },
      {
        accessorKey: "email",
        header: "Email",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru email" }
      },
      {
        accessorKey: "incarcare",
        header: "Încărcare",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru încărcare" }
      },
      {
        accessorKey: "numarTelefon",
        header: "Număr telefon",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru număr telefon" }
      },
      {
        accessorKey: "stringRoluri",
        header: "Roluri",
        filterVariant: "autocomplete",
        // Cell: ({ cell }) => (
        //   <span>
        //     {
        //       RolArray.find((obj) => {
        //         return obj.value == cell.getValue();
        //       })?.label
        //     }
        //   </span>
        // ),
        //filterSelectOptions: RolArray,
        muiFilterTextFieldProps: { placeholder: "Filtru roluri" }
      }
    ],
    []
  );

  const customToolbar = () => {
    return (
      <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
        {userInfoProvider.hasAccess(Rol.ADMINISTRATOR) && (
          <Button
            onClick={() => {
              openModal();
            }}
            variant="contained"
          >
            <Add style={{ marginRight: 10 }} />
            Adaugă utilizator
          </Button>
        )}
      </Box>
    );
  };

  const customActions = ({ row }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "center"
        }}
      >
        <>
          <IconButton
            color="primary"
            title="Vizualizează încărcare"
            onClick={() => {
              setEntitySelected(row.original);
              openIncarcareModal(true);
            }}
          >
            <Preview />
          </IconButton>
          {userInfoProvider.hasAccess(Rol.ADMINISTRATOR) && (
            <>
              <IconButton
                color="secondary"
                title="Editează"
                onClick={() => {
                  openModal(row.original, ActionType.UPDATE);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                color="warning"
                title="Resetează parolă"
                onClick={() => resetUser(row)}
              >
                <LockResetIcon />
              </IconButton>
              <IconButton
                color="error"
                title="Șterge utilizator"
                onClick={() => deleteUser(row)}
              >
                <Delete />
              </IconButton>
            </>
          )}
        </>
      </Box>
    );
  };

  const table = useMaterialReactTable({
    columns,
    data: pageData,
    initialState: {
      showColumnFilters: true,
      pagination: { pageSize: 20, pageIndex: 0 }
    },
    enableSorting: false,
    enableGlobalFilter: false,
    enableColumnActions: false,

    renderTopToolbarCustomActions: customToolbar,

    enableRowActions: true,
    renderRowActions: customActions,

    state: {
      isLoading
    },

    muiTableBodyCellProps: {
      sx: {
        borderRight: "2px solid #e0e0e0",
        whiteSpace: "break-spaces",
        maxWidth: 400
      }
    },
    localization: {
      actions: "Acțiuni",
      rowsPerPage: "Rânduri pe pagină"
    }
  });

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={
          roRO.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <MaterialReactTable table={table} />
        {open && (
          <UtilizatoriModal
            closeModal={closeModal}
            entity={entitySelected}
            reloadData={loadData}
            action={action}
          />
        )}
        {isVisibleIncarcareModal && (
          <IncarcareModal
            closeModal={() => {
              openIncarcareModal(false);
            }}
            id={entitySelected["id"]}
            nume={entitySelected["nume"]}
            prenume={entitySelected["prenume"]}
          />
        )}
      </LocalizationProvider>
    </>
  );
};

export default Utilizatori;
