import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import TaskCard from "./TaskCard.tsx";
import userInfoProvider from "../../Providers/UserInfoProvider.tsx";
import { api } from "../../Api/api.tsx";
import { notificationType, setNotification } from "../../Utils/Notificator.tsx";

const useComponentWillUnmount = (callback) => {
  const mem = useRef(callback);

  useEffect(() => {
    mem.current = callback;
  }, [callback]);

  useEffect(() => {
    return () => {
      mem.current();
    };
  }, []);
};

const Title = styled.span`
  padding: 2px 10px;
  border-radius: 5px;
  align-self: flex-start;
`;

const Kanban = ({ sarciniGenerale, sarciniUrgente }) => {
  const [columns, setColumns] = useState({
    0: {
      title: "Sarcini normale",
      items: []
    },
    1: {
      title: "Sarcini importante",
      items: []
    }
  });

  useEffect(() => {
    var copy = Object.assign({}, columns);
    copy[0].items = sarciniGenerale;
    copy[1].items = sarciniUrgente;
    setColumns(copy);
  }, [sarciniGenerale]);

  useComponentWillUnmount(() => {
    updateSarcini();
  });

  const updateSarcini = () => {
    var data = {
      sarciniGenerale: columns[0].items,
      sarciniUrgente: columns[1].items
    };

    api.sarcini.update(data).then((item) => {
      if (item == 0) {
        setNotification(
          notificationType.ERROR,
          "A apărut o eroare în procesul de salvare al sarcinilor",
          "Eroare salvare ordine sarcini"
        );
      }
    });
  };

  const updateOrdineEl = (arr: any[]) => {
    var ordine = 0;
    arr.forEach((el) => {
      el.ordine = ordine;
      ordine++;
    });
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId != destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      updateOrdineEl(sourceItems);
      updateOrdineEl(destItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      updateOrdineEl(copiedItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };

  const doneFunction = (index, columnId) => {
    var copy = Object.assign({}, columns);
    copy[columnId].items.splice(index, 1);
    setColumns(copy);
    userInfoProvider.sarciniModification(-1);
  };
  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
    >
      <div className="taskColumnStyles">
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <Droppable key={columnId} droppableId={columnId}>
              {(provided, snapshot) => (
                <div
                  className="taskList"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    backgroundColor: columnId == "0" ? "#82c3ff" : "#ff8a82"
                  }}
                >
                  <Title
                    style={{
                      background: columnId == "0" ? "#0000ff" : "#ff0000",
                      color: "#ffffff"
                    }}
                  >
                    {column.title}
                  </Title>
                  {column.items.map((item, index) => (
                    <TaskCard
                      item={item}
                      index={index}
                      doneFunc={() => doneFunction(index, columnId)}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          );
        })}
      </div>
    </DragDropContext>
  );
};

export default Kanban;
