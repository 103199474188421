export const Rol = {
  ADMINISTRATOR: "Administrator",
  AGENTTRANSPORT: "Agent trasee",
  AGENT: "Agent",
  LUCRATOR: "Lucrator"
};

export const RolArray = [
  { value: "1", label: "Administrator" },
  { value: "2", label: "Agent trasee" },
  { value: "3", label: "Agent" },
  { value: "5", label: "Lucrator" }
];
