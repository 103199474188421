import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef
} from "material-react-table";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { roRO } from "@mui/x-date-pickers/locales";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, Button, IconButton } from "@mui/material";
import { Add, Edit, Delete, History, Preview } from "@mui/icons-material";
import { ActionType } from "../../Model/ActionTypes.tsx";
import { Rol, RolArray } from "../../Model/Roluri.tsx";
import { api } from "../../Api/api.tsx";
import DepartamenteModal from "./_Departament.tsx";
import userInfoProvider from "../../Providers/UserInfoProvider.tsx";

export const Departamente = () => {
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, openchange] = useState(false);
  const [entitySelected, setEntitySelected] = useState({});
  const [action, setAction] = useState<any>();

  useEffect(() => {
    userInfoProvider.changePage("Departamente");
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    var response = await api.departamente.page(null, null);
    if (response !== undefined) {
      setPageData(response);
    }
    setIsLoading(false);
  };

  const openModal = (data = {}, action = ActionType.ADD) => {
    setAction(action);
    setEntitySelected(data);
    openchange(true);
  };

  const closeModal = () => {
    openchange(false);
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "denumire",
        header: "Denumire",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru denumire" }
      },
      {
        accessorKey: "incarcare",
        header: "Încărcare",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru încărcare" }
      }
    ],
    []
  );

  const customToolbar = () => {
    return (
      <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
        <Button
          onClick={() => {
            openModal();
          }}
          variant="contained"
        >
          <Add style={{ marginRight: 10 }} />
          Adaugă departament
        </Button>
      </Box>
    );
  };

  const customActions = ({ row }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "center"
        }}
      >
        <IconButton
          color="primary"
          title="Vizualizează"
          onClick={() => {
            openModal(row.original, ActionType.VIEW);
          }}
        >
          <Preview />
        </IconButton>
        <>
          <IconButton
            color="secondary"
            title="Editează"
            onClick={() => {
              openModal(row.original, ActionType.UPDATE);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton color="error" title="Șterge departament">
            <Delete />
          </IconButton>
        </>
      </Box>
    );
  };

  const table = useMaterialReactTable({
    columns,
    data: pageData,
    initialState: { showColumnFilters: true },

    enableSorting: false,
    enableGlobalFilter: false,
    enableColumnActions: false,

    renderTopToolbarCustomActions: customToolbar,

    enableRowActions: true,
    renderRowActions: customActions,

    state: {
      isLoading
    },

    muiTableBodyCellProps: {
      sx: {
        borderRight: "2px solid #e0e0e0",
        whiteSpace: "break-spaces",
        maxWidth: 400
      }
    },
    localization: {
      actions: "Acțiuni",
      rowsPerPage: "Rânduri pe pagină"
    }
  });

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={
          roRO.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <MaterialReactTable table={table} />
        {open && (
          <DepartamenteModal
            closeModal={closeModal}
            entity={entitySelected}
            action={action}
            reloadData={loadData}
          />
        )}
      </LocalizationProvider>
    </>
  );
};

export default Departamente;
