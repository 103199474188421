import axios from "axios";
import { headers } from "./AppSettings.tsx";
import { notificationType, setNotification } from "./Notificator.tsx";
import saveAs from "file-saver";

export const MakeRequest = async (type, url, data, closeToken = null) => {
  let config = {
    method: type,
    //url: "http://localhost:43616/" + url,
    url: "https://almaonline.ro/" + url,
    data: data,
    headers: headers
  };
  let finalConfig =
    closeToken === null
      ? config
      : Object.assign({}, config, { cancelToken: closeToken.token });

  var response = await axios(finalConfig);

  return processData(response);
};

export const MakeFileRequest = async (type, url, data) => {
  var response = await axios({
    method: type,
    //url: "http://localhost:43616/" + url,
    url: "https://almaonline.ro/" + url,
    data: data,
    headers: {
      Credentials: "include"
    },
    responseType: "blob"
  });
  let filename = "FisierInformativAlertaCN.docx";
  if (response.headers["content-disposition"] !== undefined) {
    filename = response.headers["content-disposition"]
      .split(";")
      .find((n: any) => n.includes("filename="))
      .replace("filename=", "")
      .trim();
  }
  let downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  saveAs(downloadUrl, filename);
};

const processData = (response) => {
  if (response.data != undefined && response.data != -1) {
    return response.data;
  } else {
    setNotification(
      notificationType.ERROR,
      "Au apărut erori în procesul de încărcare a datelor!",
      "Finalizare acțiune"
    );
    return undefined;
  }
};
