import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  NativeSelect,
  TextField,
  Typography
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import userInfoProvider from "../../Providers/UserInfoProvider.tsx";
import { RolArray } from "../../Model/Roluri.tsx";
import { notificationType, setNotification } from "../../Utils/Notificator.tsx";
import { api } from "../../Api/api.tsx";
import { ActionType } from "../../Model/ActionTypes.tsx";

interface Utilizator {
  id: number;
  nume: string;
  prenume: string;
  email: string;
  numarTelefon: string;
  iDuriRoluri: any[];
}

export const UtilizatoriModal = (props) => {
  const [entity, setEntity] = useState<Utilizator>(Object);
  const [selectedRols, setSelectedRols] = useState<any>([]);

  useEffect(() => {
    var oldEntity: Utilizator = { ...props.entity };
    setEntity(oldEntity);
    if (props.action != ActionType.ADD) {
      var rolArray: any[] = [];
      oldEntity.iDuriRoluri.forEach((item) => {
        var found = RolArray.find((val) => val.value == item);
        if (found) {
          rolArray.push(found);
        }
      });
      setSelectedRols(rolArray);
    }
  }, []);

  const updateEntity = (event) => {
    var copy = { ...entity };
    copy[event.target.name] = event.target.value;
    setEntity(copy);
  };

  const save = async (e) => {
    e.preventDefault();
    var objToSave = {
      ...entity,
      iDuriRoluri: selectedRols.map((obj) => obj.value)
    };
    var response;

    if (props.action == ActionType.ADD) {
      response = await api.utilizatori.save(objToSave);
    } else {
      response = await api.utilizatori.update(objToSave);
    }

    if (response.data != -1) {
      setNotification(
        notificationType.OK,
        "Utilizator adăugat",
        "Utilizatorul a fost prelucrat cu succes"
      );
      props.reloadData();
      props.closeModal();
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Dialog open={true} onClose={props.closeModal} fullWidth maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography fontSize={30}>Utilizator</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
            "& svg": {
              fontSize: 32
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form id="formTrasee" onSubmit={save}>
            <Grid
              container
              spacing={3}
              sx={{ height: "50vh", marginTop: "10px" }}
            >
              <Grid item xs={5}>
                <Grid container spacing={10}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="Nume"
                      name="nume"
                      onChange={updateEntity}
                      value={entity.nume}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="Prenume"
                      name="prenume"
                      value={entity.prenume}
                      onChange={updateEntity}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="email"
                      variant="outlined"
                      label="Email"
                      name="email"
                      value={entity.email}
                      onChange={updateEntity}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={7}>
                <Grid container spacing={10}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="Număr telefon"
                      name="numarTelefon"
                      value={entity.numarTelefon}
                      onChange={updateEntity}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={RolArray}
                      key={"value"}
                      onChange={(event, newValue) => {
                        setSelectedRols(newValue);
                      }}
                      value={selectedRols}
                      getOptionLabel={(option) => option["label"]}
                      fullWidth
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Roluri"
                          placeholder="Rol"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <>
          <DialogActions>
            <Button
              type="submit"
              form="formTrasee"
              color="success"
              variant="contained"
            >
              Salvează
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </div>
  );
};

export default UtilizatoriModal;
