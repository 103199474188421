import React from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { Layout } from "./Layout";
import { observer } from "mobx-react";
import userInfoProvider from "../Providers/UserInfoProvider.tsx";
import { toJS } from "mobx";
import Login from "../Pages/Login/Login.tsx";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

export const App = observer(() => {
  return toJS(userInfoProvider.state).isAutheticated ? (
    <>
      <ReactNotifications />
      <Layout provider={userInfoProvider}>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
        </Routes>
      </Layout>
    </>
  ) : (
    <Login />
  );
});

export default App;
