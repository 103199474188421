import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  NativeSelect,
  TextField,
  Typography
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  DateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
  roRO
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { ActionType } from "../../Model/ActionTypes.tsx";
import { api } from "../../Api/api.tsx";
import MultiLineTable from "../../Components/MultiLineTable.tsx";
import userInfoProvider from "../../Providers/UserInfoProvider.tsx";
import { notificationType, setNotification } from "../../Utils/Notificator.tsx";
import { Rol } from "../../Model/Roluri.tsx";

interface Lucrare {
  id: number;
  beneficiar: string;
  idAgent: string;
  descriere: string;
  termen: Dayjs | string;
  titlu: string;
}

export const LucrariModal = (props) => {
  const [entity, setEntity] = useState<Lucrare>(Object);
  const [agents, setAgens] = useState([]);
  const [workers, setWorkers] = useState<any>([]);

  const [departaments, setDepartaments] = useState([]);
  const [selectedDep, setSelectedDep] = useState<any>([]);
  const [fragments, setFragments] = useState<any>([
    {
      status: 0,
      idLucrator: -1
    }
  ]);

  useEffect(() => {
    const { agent, departamente, fragmenteLucrare, progres, ...newEntity } =
      props.entity;

    if (props.action == ActionType.ADD) {
      newEntity.id = 0;
      if (newEntity.termen == undefined) newEntity.termen = dayjs(new Date());
      newEntity.idAgent = userInfoProvider.getUserId();
    }

    setEntity(newEntity);

    if (fragmenteLucrare != undefined) {
      var newArr: any[] = [];
      fragmenteLucrare.forEach((item) => {
        newArr.push(Object.assign({}, item));
      });
      setFragments(newArr);
    }

    loadData();
  }, []);

  const loadData = async () => {
    await api.utilizatori.getAllAgents().then(setAgens);
    await api.utilizatori.getAllWorkers().then(setWorkers);
    if (props.isMontaj == false) {
      await api.departamente.getAll().then((resp) => {
        setDepartaments(resp);
        var defDep = props.entity["departamente"];
        if (defDep != undefined) {
          var depArray: any[] = [];
          resp.forEach((item) => {
            if (defDep.includes(item["key"])) {
              depArray.push(item);
            }
          });
          setSelectedDep(depArray);
        }
      });
    }
  };

  const updateEntity = (event) => {
    var copy = Object.assign({}, entity);
    copy[event.target.name] = event.target.value;
    setEntity(copy);
  };

  const updateTermenField = (value) => {
    var copy = Object.assign({}, entity);
    copy["termen"] = value;
    setEntity(copy);
  };

  const add = () => {
    var copy = [...fragments];
    copy.push({
      status: 0,
      idLucrator: workers[0]["key"]
    });
    setFragments(copy);
  };

  const remove = (index) => {
    var copy = [...fragments];
    copy.splice(index, 1);
    setFragments(copy);
  };

  const modifyFragment = (index: number, newVal) => {
    var copy: any[] = [...fragments];
    copy.at(index).idLucrator = newVal;
    setFragments(copy);
  };

  const save = async (e) => {
    e.preventDefault();
    if (fragments[0].idLucrator == -1) {
      fragments[0].idLucrator = workers[0]["key"];
    }

    var objToSave = {
      ...entity,
      fragmenteLucrare: fragments,
      departamente: selectedDep.map((obj) => obj.key)
    };
    objToSave["termen"] = dayjs(entity.termen).format("YYYY-MM-DDTHH:mm");
    var response;

    if (props.action == ActionType.ADD) {
      if (props.isMontaj == false) response = await api.lucrari.save(objToSave);
      else response = await api.lucrari.saveMontaj(objToSave);
    } else {
        response = await api.lucrari.update(objToSave);
    }

    if (response.data != -1) {
      setNotification(
        notificationType.OK,
        "Lucrare adăugată",
        "Lucrarea a fost prelucrată cu succes"
      );
      props.reloadData(true);
      props.closeModal();
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Dialog open={true} onClose={props.closeModal} fullWidth maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography fontSize={30}>Lucrare</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "red",
            "& svg": {
              fontSize: 32
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form id="formLucrari" onSubmit={save}>
            <Grid container spacing={3} sx={{ marginTop: "10px" }}>
              <Grid item xs={5}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      variant="outlined"
                      label="Titlu"
                      name="titlu"
                      onChange={updateEntity}
                      value={entity?.titlu}
                      fullWidth
                      required
                      disabled={props.action == ActionType.VIEW}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDateTimePicker
                        format="DD-MM-YYYY HH:mm"
                        onChange={updateTermenField}
                        value={dayjs(entity?.termen)}
                        label="Termen"
                        slotProps={{
                          textField: {
                            required: true
                          }
                        }}
                        sx={{ width: "100%" }}
                        disabled={props.action == ActionType.VIEW}
                        ampm={false}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {userInfoProvider.hasAccess(Rol.ADMINISTRATOR) && (
                  <Grid item xs={12}>
                    <NativeSelect
                      name="idAgent"
                      placeholder="Agent"
                      value={entity?.idAgent}
                      fullWidth={true}
                      onChange={updateEntity}
                      disabled={props.action == ActionType.VIEW}
                    >
                      {agents.map((c) => (
                        <option key={c["key"]} value={c["key"]}>
                          {c["value"]}
                        </option>
                      ))}
                    </NativeSelect>
                  </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={7}>
                <Grid item xs={12} sx={{ paddingBottom: "2vh" }}>
                  <TextField
                    type="text"
                    variant="outlined"
                    label="Beneficiar"
                    name="beneficiar"
                    onChange={updateEntity}
                    value={entity?.beneficiar}
                    fullWidth
                    required
                    disabled={props.action == ActionType.VIEW}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    variant="outlined"
                    label="Descriere"
                    name="descriere"
                    onChange={updateEntity}
                    value={entity?.descriere}
                    rows={6}
                    required
                    multiline
                    fullWidth
                    disabled={props.action == ActionType.VIEW}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ marginTop: "10px" }}>
              <Grid item xs={6}>
                <MultiLineTable
                  users={workers}
                  fields={fragments}
                  action={props.action}
                  add={add}
                  remove={remove}
                  modify={modifyFragment}
                />
              </Grid>
              <Grid item xs={6}>
                {!props.isMontaj && (
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={departaments}
                    key={"key"}
                    onChange={(event, newValue) => {
                      setSelectedDep(newValue);
                    }}
                    value={selectedDep}
                    getOptionLabel={(option) => option["value"]}
                    fullWidth
                    filterSelectedOptions
                    disabled={props.action == ActionType.VIEW}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Departament"
                        placeholder="Departamente"
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {props.action != ActionType.VIEW ? (
          <>
            <DialogActions>
              <Button
                type="submit"
                form="formLucrari"
                color="success"
                variant="contained"
              >
                Salvează
              </Button>
            </DialogActions>
          </>
        ) : (
          ""
        )}
      </Dialog>
    </div>
  );
};

export default LucrariModal;
