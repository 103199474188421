import Swal from "sweetalert2";

export const showModal = (value, confFunc = () => {}, denyFunc = () => {}) => {
  let standardProps = {
    title: "Esti sigur?",
    text: "Această operațiune nu este reversibilă!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Da",
    cancelButtonText: "Nu",
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  let swalProps = Object.assign({}, standardProps, value);

  swalWithBootstrapButtons.fire(swalProps).then((res) => {
    if (res.isConfirmed) {
      confFunc();
    } else {
      denyFunc();
    }
  });
};
