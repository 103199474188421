import { NativeSelect, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ActionType } from "../Model/ActionTypes.tsx";
import { Delete } from "@mui/icons-material";
import { Box } from "@mui/system";

const sablonObiectFormular = {
  status: 0,
  idLucrator: 1
};

export const MultiLineTable = ({
  fields,
  users,
  action,
  add,
  remove,
  modify
}) => {
  return fields.length > 0 ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
      }}
    >
      {action != ActionType.VIEW ? (
        <Button
          color="primary"
          variant="contained"
          onClick={() => add()}
          style={{ marginBottom: "1vh" }}
        >
          Adaugă pas
        </Button>
      ) : (
        <></>
      )}
      <table className="fragmenteLucrariTable">
        <tr>
          <th>Lucrător</th>
          <th>Status</th>
        </tr>
        {fields.map((inputField, index) => (
          <tr>
            <td style={{ width: "50%" }}>
              <NativeSelect
                name="idLucrator"
                placeholder="Lucrător"
                fullWidth={true}
                disabled={action == ActionType.VIEW}
                value={inputField["idLucrator"]}
                onChange={e => modify(index,Number(e.target.value))}
              >
                {users.map((c) => (
                  <option key={c["key"]} value={c["key"]}>
                    {c["value"]}
                  </option>
                ))}
              </NativeSelect>
            </td>
            <td style={{ width: "30%" }}>
              <Typography
                sx={{
                  backgroundColor:
                    inputField["status"] == 0 ? "yellow" : "green",
                  borderRadius: "25px",
                  padding: "5px 10px 5px 10px",
                  fontWeight: "bold"
                }}
              >
                {inputField["status"] == 0 ? "În lucru" : "Finalizat"}
              </Typography>
            </td>
            {action != ActionType.VIEW ? (
              <td style={{ width: "20%" }}>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => remove(index)}
                >
                  <Delete />
                </Button>
              </td>
            ) : (
              <></>
            )}
          </tr>
        ))}
      </table>
    </Box>
  ) : (
    <></>
  );
};

MultiLineTable.defaultProps = {
  fields: {},
  departaments: [],
  users: [],
  action: ActionType.ADD
};

export default MultiLineTable;
