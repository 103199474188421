import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import AdbIcon from "@mui/icons-material/Adb";
import { useNavigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import SarciniModal from "../Pages/Sarcini/_SarciniModal.tsx";
import userInfoProvider from "../Providers/UserInfoProvider.tsx";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { showModal } from "../Utils/ShowModalMessage.tsx";

export const Navbar = observer(() => {
  const [open, openchange] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    userInfoProvider.updateSarciniActive();
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const changePage = (page) => {
    handleCloseNavMenu();
    navigate(page, { replace: true });
  };

  const openModal = () => {
    openchange(true);
  };
  const closeModal = () => {
    openchange(false);
  };

  const reconnect = () => {
    showModal(
      {
        title: "Ești sigur că dorești să te deconectezi?"
      },
      () => {
        userInfoProvider.disconnect();
        window.location.reload(false);
      }
    );
  };

  const renderMobileMenu = (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none" },
            color: "blue"
          }}
        >
          {AppRoutes.map(
            (page) =>
              userInfoProvider.hasAccess(page.rols) && (
                <MenuItem
                  key={page.menuName}
                  onClick={() => {
                    changePage(page.path);
                  }}
                >
                  <Typography>{page.menuName}</Typography>
                </MenuItem>
              )
          )}
        </Menu>
      </Box>
      <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
    </>
  );

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AdbIcon
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 3,
              fontSize: "30px"
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" }
            }}
          >
            {AppRoutes.map(
              (page) =>
                userInfoProvider.hasAccess(page.rols) && (
                  <Button
                    key={page.menuName}
                    onClick={() => {
                      changePage(page.path);
                    }}
                    sx={{
                      color: "white",
                      display: "block",
                      fontFamily: "Nunito",
                      fontWeight: "bold",
                      ml: "1vw",
                      fontSize: "18px"
                    }}
                  >
                    {page.menuName}
                  </Button>
                )
            )}
          </Box>

          {renderMobileMenu}
          <>
            <Box sx={{ "margin-right": "2vw" }}>
              <IconButton size="large" color="inherit" onClick={openModal}>
                <Badge
                  badgeContent={toJS(userInfoProvider.state).sarciniActive}
                  color="error"
                >
                  <TaskAltIcon sx={{ fontSize: "30px", marginRight: "10px" }} />
                </Badge>
              </IconButton>
              <IconButton>
                <LogoutIcon
                  color="error"
                  sx={{ fontSize: "40px" }}
                  onClick={reconnect}
                />
              </IconButton>
            </Box>
            {open && <SarciniModal isOpen={open} closeModal={closeModal} />}
          </>
        </Toolbar>
      </Container>
    </AppBar>
  );
});

export default Navbar;
