import { action, makeAutoObservable, toJS } from "mobx";
import Cookies from "js-cookie";
import axios from "axios";
import { Rol } from "../Model/Roluri.tsx";
import { api } from "../Api/api.tsx";

class UserInfoProvider {
  state = {
    userId: -1,
    rols: [],
    isAutheticated: false,
    sarciniActive: 0
  };

  utils = {
    currentPage: ""
  };

  disconnect = action(() => {
    Cookies.remove("token", { path: "" });
  });

  getUserId = action(() => {
    return this.state.userId;
  });

  setIsAuthenticated = action((value) => (this.state.isAutheticated = value));

  setUser = action((token, addToCookie = true, daysUntilExpire = 1) => {
    const decodedObj: any = JSON.parse(window.atob(token.split(".")[1]));

    Object.keys(decodedObj).forEach((key) => {
      let res = key.split("/");
      if (res.length > 1) {
        if (res[res.length - 1] === "role") {
          if (Array.isArray(decodedObj[key]) == true) {
            this.state.rols = decodedObj[key];
          } else {
            this.state.rols.push(decodedObj[key]);
          }
        } else if (res[res.length - 1] === "name") {
          this.state.userId = decodedObj[key];
        }
      }
    });
    if (this.state.userId != -1) {
      this.state.isAutheticated = true;
      if (addToCookie == true)
        Cookies.set("token", token, { expires: daysUntilExpire });
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    }
  });

  hasAccess = action((rol) => {
    var isPresent = false;
    if (this.state.rols.find((el) => el == Rol.ADMINISTRATOR) != undefined) {
      isPresent = true;
    }

    if (Array.isArray(rol) == false) {
      if (this.state.rols.find((el) => el == rol) != undefined) {
        isPresent = true;
      }
    } else {
      rol.forEach((element) => {
        if (isPresent == false)
          if (this.state.rols.find((el) => el == element) != undefined) {
            isPresent = true;
          }
      });
    }
    return isPresent;
  });

  updateSarciniActive = action(async () => {
    var resp = await api.sarcini.count();
    if (resp != undefined) {
      this.state.sarciniActive = resp;
    }
  });

  sarciniModification = action((val) => {
    this.state.sarciniActive += val;
  });

  changePage = action((val) => {
    this.utils.currentPage = val;
  });

  constructor() {
    makeAutoObservable(this);
  }
}

const userInfoProvider = new UserInfoProvider();

export default userInfoProvider;
