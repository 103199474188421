import { MakeRequest } from "../Utils/MakeHttpRequest.tsx";
import { baseApi } from "./BaseApi.tsx";

const cereriBase = "cereri/";
const utilizatoriBase = "utilizatori/";
const lucrariBase = "lucrari/";
const departamenteBase = "departamente/";
const sarciniBase = "sarcini/";
const traseeBase = "trasee/";
const roluriBase = "roluri/";

export const api = {
  cereri: {
    ...baseApi(cereriBase),
    getActive: () => MakeRequest("post", cereriBase + "pageActive", null),
    process: (id) => MakeRequest("post", cereriBase + "procesare", id),
    handle: (id) => MakeRequest("post", cereriBase + "finalizare", id)
  },

  utilizatori: {
    ...baseApi(utilizatoriBase),
    getAllAgents: () => MakeRequest("get", utilizatoriBase + "agenti", null),
    getAllWorkers: () =>
      MakeRequest("get", utilizatoriBase + "lucratori", null),
    login: (data) => MakeRequest("post", utilizatoriBase + "login", data),
    loginWithPassReset: (data) =>
      MakeRequest("post", utilizatoriBase + "loginWithPassReset", data),
    userLoad: (id) =>
      MakeRequest("get", utilizatoriBase + "userLoad/" + id, null),
    resetPassword: (id) =>
      MakeRequest("post", utilizatoriBase + "resetPassword", id)
  },

  lucrari: {
    ...baseApi(lucrariBase),
    getActive: () => MakeRequest("post", lucrariBase + "pageActive", null),
    done: (id) => MakeRequest("post", lucrariBase + "finalizare", id),
    getAllMontaje: () => MakeRequest("post", lucrariBase + "montaje", null),
    getMontajeActive: () =>
      MakeRequest("post", lucrariBase + "montajeActive", null),
    saveMontaj: (data) => MakeRequest("post", lucrariBase + "saveMontaj", data)
  },

  departamente: {
    ...baseApi(departamenteBase),
    getAll: () => MakeRequest("get", departamenteBase + "getList", null)
  },

  sarcini: {
    ...baseApi(sarciniBase),
    getAll: () => MakeRequest("get", sarciniBase + "getList", null),
    count: () => MakeRequest("get", sarciniBase + "count", null)
  },

  trasee: {
    ...baseApi(traseeBase),
    getActive: () => MakeRequest("post", traseeBase + "pageActive", null),
    done: (id) => MakeRequest("post", traseeBase + "finalizare", id)
  }
};
