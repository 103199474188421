import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef
} from "material-react-table";
import React from "react";
import { StatusCerere } from "../../Model/Cerere.tsx";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { roRO } from "@mui/x-date-pickers/locales";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { api } from "../../Api/api.tsx";
import { Box, Button, IconButton } from "@mui/material";
import {
  Add,
  Edit,
  Done,
  History,
  Preview,
  NotInterested,
  AddIcCallRounded
} from "@mui/icons-material";
import { CereriModal } from "./_CereriModal.tsx";
import { ActionType } from "../../Model/ActionTypes.tsx";
import { showModal } from "../../Utils/ShowModalMessage.tsx";
import TableTooltipCell from "../../Components/TableTooltipCell.tsx";
import { notificationType, setNotification } from "../../Utils/Notificator.tsx";
import LucrariModal from "../Lucrari/_LucrariModal.tsx";
import userInfoProvider from "../../Providers/UserInfoProvider.tsx";
import { toJS } from "mobx";
import { Rol } from "../../Model/Roluri.tsx";
import { DatePicker } from "@mui/x-date-pickers";

export const Cereri = () => {
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, openchange] = useState(false);
  const [openLucrari, openLucrariChange] = useState(false);
  const [entitySelected, setEntitySelected] = useState({});
  const [action, setAction] = useState<any>();
  const [isHistory, setIsHistory] = useState(false);

  useEffect(() => {
    userInfoProvider.changePage("Cereri");
    loadData();
  }, [isHistory]);

  const loadData = async () => {
    setIsLoading(true);
    var response;

    if (isHistory === false) {
      response = await api.cereri.getActive();
      if (response !== undefined) {
        setPageData(response);
      }
    } else {
      response = await api.cereri.page(null, null);
      if (response !== undefined) {
        setPageData(response);
      }
    }
    setIsLoading(false);
  };

  const deleteEntity = (row) => {
    showModal(
      { title: "Ești sigur că această cerere a fost refuzată?" },
      async () => {
        if ((await api.cereri.delete(row.original.id)) != undefined) {
          pageData.splice(row.index, 1);
          setPageData([...pageData]);
          setNotification(
            notificationType.OK,
            "Cerere a fost refuzată",
            "Modificare status cerere"
          );
        }
      }
    );
  };

  const processCerere = (row) => {
    showModal(
      {
        title: "Ești sigur că ai discutat cu beneficiarul cererii?"
      },
      async () => {
        if ((await api.cereri.process(row.original.id)) != undefined) {
          loadData();
          setNotification(
            notificationType.OK,
            "Status cerere modificat",
            "Modificare status cerere"
          );
        }
      }
    );
  };

  const handleCerere = (row) => {
    showModal(
      {
        title: "Ai rezolvat această cerere?"
      },
      async () => {
        openModal(openLucrariChange, row.original);
      }
    );
  };

  const handleFinalCerere = async () => {
    if ((await api.cereri.handle(entitySelected["id"])) != undefined) {
      loadData();
      setNotification(
        notificationType.OK,
        "Status cerere modificat",
        "Modificare status cerere"
      );
    }
  };

  const openModal = (modalOpenFunc, data = {}, action = ActionType.ADD) => {
    setAction(action);
    setEntitySelected(data);
    modalOpenFunc(true);
  };

  const closeModal = () => {
    openchange(false);
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "beneficiar",
        header: "Beneficiar",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru beneficiar" }
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.termen),
        accessorKey: "Termen",
        header: "Termen",
        filterVariant: "date",
        muiFilterDatePickerProps: {
          format: "dd/MM/yyyy"
        },
        filterFn: (row: any, id, filterValue) => {
          row.getValue(id).setHours(0, 0, 0, 0);
          filterValue.setHours(0, 0, 0, 0);
          return row.getValue(id).getTime() == filterValue.getTime();
        },
        size: 50,
        Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString("ro-RO"),
        muiFilterTextFieldProps: { placeholder: "Filtru termen" }
      },
      {
        accessorKey: "agent",
        header: "Agent",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru agent" }
      },
      {
        accessorKey: "status",
        header: "Status",
        filterVariant: "select",
        Cell: ({ cell }) => (
          <span>
            {
              StatusCerere.find((obj) => {
                return obj.value == cell.getValue();
              })?.label
            }
          </span>
        ),
        filterSelectOptions: StatusCerere,
        muiFilterTextFieldProps: { placeholder: "Filtru status" }
      },
      {
        Cell: ({ cell }) => <TableTooltipCell value={cell.getValue()} />,
        accessorKey: "descriere",
        header: "Descriere",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru descriere" },
        size: 400
      }
    ],
    []
  );

  const customToolbar = () => {
    return (
      <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
        <Button
          onClick={() => {
            openModal(openchange);
          }}
          variant="contained"
        >
          <Add style={{ marginRight: 10 }} />
          Adaugă cerere
        </Button>
        <Button
          onClick={() => {
            setIsHistory(!isHistory);
          }}
          variant="contained"
          sx={{ backgroundColor: "brown" }}
        >
          <History style={{ marginRight: 10 }} />
          {isHistory === false ? "Istoric" : "Active"}
        </Button>
      </Box>
    );
  };

  const shouldShowActionButtons = (entity) => {
    return (
      isHistory == false &&
      (entity.idAgent == toJS(userInfoProvider.state).userId ||
        userInfoProvider.hasAccess(Rol.ADMINISTRATOR))
    );
  };

  const customActions = ({ row }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "center"
        }}
      >
        <IconButton
          color="primary"
          title="Vizualizează"
          onClick={() => {
            openModal(openchange, row.original, ActionType.VIEW);
          }}
        >
          <Preview />
        </IconButton>
        {shouldShowActionButtons(row.original) ? (
          <>
            <IconButton
              color="secondary"
              title="Editează"
              onClick={() => {
                openModal(openchange, row.original, ActionType.UPDATE);
              }}
            >
              <Edit />
            </IconButton>
            {row.original.status != 0 ? (
              <>
                <IconButton
                  color="error"
                  title="Cerere respinsă"
                  onClick={() => deleteEntity(row)}
                >
                  <NotInterested />
                </IconButton>
                <IconButton color="success" onClick={() => handleCerere(row)}>
                  <Done />
                </IconButton>
              </>
            ) : (
              <IconButton
                color="warning"
                title="Cerere prelucrată"
                onClick={() => processCerere(row)}
              >
                <AddIcCallRounded />
              </IconButton>
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const table = useMaterialReactTable({
    columns,
    data: pageData,
    initialState: {
      showColumnFilters: true,
      pagination: { pageSize: 20, pageIndex: 0 }
    },
    enableSorting: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    renderTopToolbarCustomActions: customToolbar,
    enableRowActions: true,
    renderRowActions: customActions,
    state: {
      isLoading
    },

    muiTableBodyCellProps: {
      sx: {
        borderRight: "2px solid #e0e0e0",
        whiteSpace: "break-spaces",
        maxWidth: 400
      }
    },
    localization: {
      actions: "Acțiuni",
      rowsPerPage: "Rânduri pe pagină"
    }
  });

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={
          roRO.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <MaterialReactTable table={table} />
        {open && (
          <CereriModal
            closeModal={closeModal}
            entity={entitySelected}
            action={action}
            reloadData={loadData}
          />
        )}
        {openLucrari && (
          <LucrariModal
            closeModal={() => {
              openLucrariChange(false);
            }}
            entity={entitySelected}
            action={action}
            reloadData={handleFinalCerere}
            isMontaj={false}
          />
        )}
      </LocalizationProvider>
    </>
  );
};

export default Cereri;
