import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef
} from "material-react-table";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { roRO } from "@mui/x-date-pickers/locales";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { api } from "../../Api/api.tsx";
import { Box, Button, IconButton } from "@mui/material";
import { Add, Edit, Done, History, Preview } from "@mui/icons-material";
import { LucrariModal } from "./_LucrariModal.tsx";
import { ActionType } from "../../Model/ActionTypes.tsx";
import TableTooltipCell from "../../Components/TableTooltipCell.tsx";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";
import userInfoProvider from "../../Providers/UserInfoProvider.tsx";
import { Rol } from "../../Model/Roluri.tsx";
import { showModal } from "../../Utils/ShowModalMessage.tsx";
import { notificationType, setNotification } from "../../Utils/Notificator.tsx";
import { toJS } from "mobx";

export const Lucrari = () => {
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, openchange] = useState(false);
  const [entitySelected, setEntitySelected] = useState({});
  const [action, setAction] = useState<any>();
  const [isHistory, setIsHistory] = useState(false);

  useEffect(() => {
    userInfoProvider.changePage("Lucrări");
    loadData();
  }, [isHistory]);

  const loadData = async () => {
    setIsLoading(true);
    var response;

    if (isHistory === false) {
      response = await api.lucrari.getActive();
      if (response !== undefined) {
        setPageData(response);
      }
    } else {
      response = await api.lucrari.page(null, null);
      if (response !== undefined) {
        setPageData(response);
      }
    }
    setIsLoading(false);
  };

  const openModal = (data = {}, action = ActionType.ADD) => {
    setAction(action);
    setEntitySelected(data);
    openchange(true);
  };

  const closeModal = () => {
    openchange(false);
  };

  const done = (row) => {
    showModal(
      {
        title: "Ești sigur că ai finalizat lucrarea?"
      },
      async () => {
        if ((await api.lucrari.done(row.original.id)) != undefined) {
          loadData();
          setNotification(
            notificationType.OK,
            "Status lucrare modificat",
            "Modificare status lucrare"
          );
        }
      }
    );
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "titlu",
        header: "Titlu",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru titlu" }
      },
      {
        accessorKey: "beneficiar",
        header: "Beneficiar",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru beneficiar" }
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.termen),
        accessorKey: "Termen",
        header: "Termen",
        filterVariant: "date",
        muiFilterDatePickerProps: {
          format: "dd/MM/yyyy"
        },
        filterFn: (row: any, id, filterValue) => {
          var tableDate = new Date(row.getValue(id));
          tableDate.setHours(0, 0, 0, 0);
          filterValue.setHours(0, 0, 0, 0);
          return tableDate.getTime() == filterValue.getTime();
        },
        size: 50,
        Cell: ({ cell }) => {
          var time = cell.getValue<Date>();
          return (
            time.toLocaleDateString("ro-RO") +
            ":" +
            time.toLocaleTimeString("ro-RO", {
              hour: "2-digit",
              minute: "2-digit"
            })
          );
        },
        muiFilterTextFieldProps: { placeholder: "Filtru termen" }
      },
      {
        accessorKey: "agent",
        header: "Agent",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru agent" }
      },
      {
        accessorKey: "progres",
        header: "Progres",
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <LinearProgress
            determinate
            variant="outlined"
            color="primary"
            size="sm"
            thickness={32}
            value={cell.getValue() as number}
            sx={{
              "--LinearProgress-radius": "0px",
              "--LinearProgress-progressThickness": "24px",
              boxShadow: "sm",
              borderColor: "blue"
            }}
          >
            <Typography
              level="body-xs"
              fontWeight="xl"
              sx={{ color: "#000000", zIndex: 10 }}
            >
              {`${Math.round(cell.getValue() as number)}%`}
            </Typography>
          </LinearProgress>
        )
      },
      {
        Cell: ({ cell }) => <TableTooltipCell value={cell.getValue()} />,
        accessorKey: "descriere",
        header: "Descriere",
        filterVariant: "autocomplete",
        muiFilterTextFieldProps: { placeholder: "Filtru descriere" },
        size: 400
      }
    ],
    []
  );

  const shouldShowEditButton = (entity) => {
    return (
      entity.idAgent == toJS(userInfoProvider.state).userId ||
      userInfoProvider.hasAccess(Rol.ADMINISTRATOR)
    );
  };

  const shouldShowDoneButton = (entity) => {
    return (
      shouldShowEditButton(entity) ||
      entity.fragmenteLucrare.find(
        (e) => e.idLucrator == toJS(userInfoProvider.state).userId
      ) != undefined
    );
  };

  const customToolbar = () => {
    return (
      <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
        {userInfoProvider.hasAccess([Rol.AGENT]) && (
          <Button
            onClick={() => {
              openModal();
            }}
            variant="contained"
          >
            <Add style={{ marginRight: 10 }} />
            Adaugă lucrare
          </Button>
        )}
        <Button
          onClick={() => {
            setIsHistory(!isHistory);
          }}
          variant="contained"
          sx={{ backgroundColor: "brown" }}
        >
          <History style={{ marginRight: 10 }} />
          {isHistory === false ? "Istoric" : "Active"}
        </Button>
      </Box>
    );
  };

  const customActions = ({ row }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "center"
        }}
      >
        <IconButton
          color="primary"
          title="Vizualizează"
          onClick={() => {
            openModal(row.original, ActionType.VIEW);
          }}
        >
          <Preview />
        </IconButton>
        {isHistory == false && row.original.status != 1 ? (
          <>
            {shouldShowEditButton(row.original) && (
              <IconButton
                color="secondary"
                title="Editează"
                onClick={() => {
                  openModal(row.original, ActionType.UPDATE);
                }}
              >
                <Edit />
              </IconButton>
            )}
            {shouldShowDoneButton(row.original) && (
              <IconButton
                color="success"
                title="Finalizează lucrarea"
                onClick={() => done(row)}
              >
                <Done />
              </IconButton>
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const table = useMaterialReactTable({
    columns,
    data: pageData,
    initialState: {
      showColumnFilters: true,
      pagination: { pageSize: 20, pageIndex: 0 }
    },
    enableSorting: false,
    enableGlobalFilter: false,
    enableColumnActions: false,

    renderTopToolbarCustomActions: customToolbar,

    enableRowActions: true,
    renderRowActions: customActions,

    state: {
      isLoading
    },

    muiTableBodyCellProps: {
      sx: {
        borderRight: "2px solid #e0e0e0",
        whiteSpace: "break-spaces",
        maxWidth: 400
      }
    },
    localization: {
      actions: "Acțiuni",
      rowsPerPage: "Rânduri pe pagină"
    }
  });

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={
          roRO.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <MaterialReactTable table={table} />
        {open && (
          <LucrariModal
            closeModal={closeModal}
            entity={entitySelected}
            action={action}
            reloadData={loadData}
            isMontaj={false}
          />
        )}
      </LocalizationProvider>
    </>
  );
};

export default Lucrari;
